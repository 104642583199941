import baseService from "./baseService";

export default class ExpensesService extends baseService {
    constructor() {
        super();
        this.schemaName = "expenses";
    }

    getExpenseList(entity) {
        return this.getObject(this.schemaName, "list-expenses/", entity)
    }

    patchVerified(entity, id) {
        return this.patchObject("expense/verified", id, entity);
    }


    patchVerifiedMultiple(entity) {
        return this.patchObject("/expense/verified-multiple", "", entity);
    }


}