import React, { useCallback } from 'react';
import { Form, Input, Select, DatePicker, TimePicker, Radio, Button, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import { GetWord } from '../../../../../../utils';
import moment from 'moment';
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat';
import Service from '../../../../../../Service';

const { TextArea } = Input;

const NotificationMarket = ({ setIsRefresh, setModal }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();


    const onFinish = useCallback((values) => {
        const query = `hasVehicle=${values.checkbox.includes('hasVehicle')}&hasExpenses=${values.checkbox.includes('hasExpenses')}&hasInactiveUsersWeek=${values.checkbox.includes('hasInactiveUsersWeek')}`;
        const service = new Service();
        service.NotificationService.postMarket(values, query).then((res) => {
            if (res) {
                setModal(false);
                setIsRefresh((prev) => !prev);
            }
        })
    }, [setIsRefresh, setModal]);


    dayjs.extend(customParseFormat);

    return (
        <div className='notification-modal-market'>
            <Form
                onFinish={onFinish}
            >
                <div className='notification-modal-market-content'>
                    {/* <span className='notification-modal-market-content-title'>
                        {GetWord('İçerik')}
                    </span> */}
                    <div className='notification-modal-market-content-form'>
                        <div className='notification-modal-market-content-form-titleimage'>
                            <Form.Item className='notification-modal-market-content-input' name='title'>
                                <Input placeholder={GetWord('Başlık')} />
                            </Form.Item>
                            <Form.Item className='notification-modal-market-content-url'
                                name="image"
                                rules={[
                                    {
                                        required: true,
                                    },
                                    {
                                        type: 'url',
                                        warningOnly: true,
                                    },
                                    {
                                        type: 'string',
                                        min: 6,
                                    },
                                ]}
                            >
                                <Input placeholder={GetWord('Görsel URL')} />
                            </Form.Item>
                        </div>
                        <div className='notification-modal-market-content-form-content'>
                            <Form.Item className='notification-modal-market-content-textarea' name="body">
                                <TextArea rows={4}
                                    placeholder={GetWord('İçerik')}
                                    style={{
                                        resize: 'none',
                                    }}
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <div className='notification-modal-market-content-form-screen'>
                        <div className='notification-modal-personalized-targetgroup-selectarea-ownersarea'>
                            <span className='notification-modal-personalized-targetgroup-selectarea-ownersarea-title'>
                                {GetWord('Ekran Yönlendir')}
                            </span>

                            <Form.Item className='notification-modal-personalized-targetgroup-selectarea-ownersarea-select' name="navigationScreen">
                                <Select
                                    defaultValue={'notifications'}
                                    options={[
                                        { value: 'notifications', label: GetWord('Bildirimler') },
                                        { value: 'FuelPrices', label: GetWord('Yakıt Fiyatları') },
                                    ]}
                                />
                            </Form.Item>
                        </div>

                        <div className='notification-modal-personalized-targetgroup-selectarea-ownersarea'>
                            <span className='notification-modal-personalized-targetgroup-selectarea-ownersarea-title'>
                                {GetWord('Tür')}
                            </span>

                            <Form.Item className='notification-modal-personalized-targetgroup-selectarea-ownersarea-select'>
                                <Select
                                    defaultValue={'general'}
                                    options={[
                                        { value: 'general', label: GetWord('Genel') },
                                        { value: 'station', label: GetWord('İstasyon') },
                                    ]}
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <div className='notification-modal-market-content-form-screen-radio'>
                        <Form.Item name="checkbox" className='notification-modal-market-content-form-screen-radio'>
                            <Checkbox.Group>
                                <Checkbox value='hasVehicle'>{GetWord('Aracı Olanlar')}</Checkbox>
                                <Checkbox value='hasExpenses'>{GetWord('Masraf Girişi Yapanlar')}</Checkbox>
                                <Checkbox value='hasInactiveUsersWeek'>{GetWord('Son 7 Günde Aktif Olmayanlar')}</Checkbox>
                            </Checkbox.Group>
                        </Form.Item>
                    </div>
                </div>

                {/* 
                <div className='notification-modal-campaign-datetime'>
                    <span className='notification-modal-campaign-datetime-title'>
                        {GetWord('Gönderilecek Tarih & Saat')}
                    </span>
                    <div className='notification-modal-campaign-datetime-datetimearea'>

                        <div className='notification-modal-campaign-datetime-datetimearea-datearea'>
                            <span className='notification-modal-campaign-datetime-datetimearea-datearea-title'>
                                {GetWord('Tarih')}
                            </span>

                            <Form.Item className='notification-modal-campaign-datetime-datetimearea-datearea-select'>
                                <DatePicker onChange={onChange}
                                    format={dateFormat}
                                    placeholder={today}
                                />
                            </Form.Item>
                        </div>

                        <div className='notification-modal-campaign-datetime-datetimearea-timearea'>
                            <span className='notification-modal-campaign-datetime-datetimearea-timearea-title'>
                                {GetWord('Saat')}
                            </span>

                            <Form.Item className='notification-modal-campaign-datetime-datetimearea-timearea-select'>
                                <TimePicker onChange={onChange}
                                    format={timeformat}
                                    placeholder={currentTime}

                                />
                            </Form.Item>
                        </div>
                    </div>
                </div> */}
                <Form.Item className='notification-modal-campaign-content-sendbutton'>
                    <Button htmlType="submit" type="primary" className='notification-modal-campaign-content-sendbutton-style'>
                        <span className='notification-modal-campaign-content-sendbutton-text'>
                            {GetWord('Gönder')}</span>
                    </Button>
                </Form.Item>
            </Form>

        </div>
    );
};

export default NotificationMarket;