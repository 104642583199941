import React, { memo, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setlogout } from "../../../../stores/auth";
import { signoutUser } from "../../../../utils/firebase";
import { Badge, Button, Dropdown, Image, Menu } from "antd";
import './scss/header.scss'
import PetrosLogo from '../../../../../src/assest/images/icon.svg'
import { setSideBtn } from "../../../../stores/site";

const Header = ({ pageName, isActive }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [items, setNotification] = useState([]);

  const logoutHandler = async () => {
    await signoutUser();
    dispatch(setlogout());
    navigate("/auth");
  };

  const itemss = [
    {
      key: '1',
      label: (
        <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
          1st menu item
        </a>
      ),
    },

    {
      key: '3',
      label: (
        <a target="_blank" rel="noopener noreferrer" href="https://www.luohanacademy.com">
          3rd menu item (disabled)
        </a>
      ),
      disabled: true,
    },
    {
      key: '4',
      danger: true,
      label: 'a danger item',
    },
  ];


  return (
    <header className="header">
      <div className={"header-left " + (isActive ? "active" : "")}>
        <div className="header-left-logo">
          <Image src={PetrosLogo} className="header-left-logo-icon" />
          <span className="header-left-logo-name">Petros</span>
        </div>
        <div className="header-left-exit" onClick={() => dispatch(setSideBtn((prev) => !prev))}>
          <i className={isActive ? "icon-Menu-Close header-left-exit-icon" : "icon-Menu header-left-exit-icon"} />
        </div>
      </div>
      <div className="header-right">
        <div className="header-right-text">
          <p className="header-right-text-title">
            <span>Petros Admin</span>Paneline Hoşgeldiniz
          </p>
        </div>
        <div className="header-right-icons">
          <i className="icon-Setting header-right-icons-settings" />
          <Dropdown overlayClassName="header-dropdown"
            overlay={(
              <Menu>
                <Menu.Item key="1">
                  <div className="header-dropdown-notification-content-new" />
                  <div className="header-dropdown-notification-content">
                    <span className="header-dropdown-notification-content-title">
                      Başlık
                    </span>
                    <span className="header-dropdown-notification-content-desc">
                      Açıklama
                    </span>
                    <Button className="header-dropdown-notification-content-link">
                      <span className="heade-dropdownr-notification-content-link-text">
                        Link
                      </span>
                    </Button>
                  </div>
                </Menu.Item>
                <Menu.Item key="2">
                  <div className="header-dropdown-notification-content-new" />
                  <div className="header-dropdown-notification-content">
                    <span className="header-dropdown-notification-content-title">
                      Başlık
                    </span>
                    <span className="header-dropdown-notification-content-desc">
                      Açıklama
                    </span>
                  </div>
                </Menu.Item>
                <div className="header-dropdown-notification-newnotification">
                  <Button className="header-dropdown-notification-newnotification-button">
                    <span className="header-dropdown-notification-newnotification-button-text">Yeni Bildirimler(2)</span><i className="icon-Arrow-Up-N header-dropdown-notification-newnotification-button-icon" />
                  </Button>
                </div>
                <Menu.Item key="2">
                  <div className="header-dropdown-notification-content">
                    <span className="header-dropdown-notification-content-title">
                      Başlık
                    </span>
                    <span className="header-dropdown-notification-content-desc">
                      Açıklama
                    </span>
                  </div>
                </Menu.Item>
                <Menu.Item key="2">
                  <div className="header-dropdown-notification-content">
                    <span className="header-dropdown-notification-content-title">
                      Başlık
                    </span>
                    <span className="header-dropdown-notification-content-desc">
                      Açıklama
                    </span>
                  </div>
                </Menu.Item>
                <Menu.Item key="2">
                  <div className="header-dropdown-notification-content">
                    <span className="header-dropdown-notification-content-title">
                      Başlık
                    </span>
                    <span className="header-dropdown-notification-content-desc">
                      Açıklama
                    </span>
                  </div>
                </Menu.Item>
                <Menu.Item key="2">
                  <div className="header-dropdown-notification-content">
                    <span className="header-dropdown-notification-content-title">
                      Başlık
                    </span>
                    <span className="header-dropdown-notification-content-desc">
                      Açıklama
                    </span>
                  </div>
                </Menu.Item>
              </Menu>
            )}
            placement="bottomRight"
            arrow
          >
            <Badge count={2}>
              <Button className="header-right-icons-notification-button">
                <i className="icon-Notification header-right-icons-notification" />
              </Button>
            </Badge>
          </Dropdown>
          <Dropdown
            overlay={(<Menu>
              <Menu.Item key="1" onClick={() => {
                localStorage.removeItem("user");
                localStorage.removeItem("tkn");
                navigate("/auth");
              }}>
                Çıkış Yap
              </Menu.Item>
            </Menu>)}
            trigger={['click']}
          >
            <div className="header-right-icons-profile" />
          </Dropdown>


        </div>
      </div>
    </header>
  );
};

export default memo(Header);
