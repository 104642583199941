import React from 'react'
import '../expenses.scss'

const Viewexpenses = ({ data }) => {
    return (
        <div className='expenses-viewexpenses'>
            <div className='expenses-viewexpenses-body'>
                {data && <img src={data.expenseImage} alt="expense" className='expenses-viewexpenses-image' />}
            </div>
        </div>
    );
}

export default Viewexpenses;
