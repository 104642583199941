import { Tabs } from 'antd'
import React, { useState } from 'react'
import Public from './components/public'
import Campaign from './components/campaign'
import Personalized from './components/personalized'
import Attractuser from './components/attractuser'
import { GetWord } from '../../../../../utils'
import NotificationMarket from './components/notificationMarket'
import NotificatioTimed from './components/NotificatioTimed'
import NotificationDependingOnVehicle from './components/NotificationDependingOnVehicle'
import NotificationTest from './components/NotificationTest'

const Notificationmodal = ({ setSelectedTab, setIsRefresh, setModal, selectedData, defaultActiveKey = '1', isVisableAllNotTimed, isVisabledTimed }) => {
    const onChange = (key) => {
        setSelectedTab(key)
    };

    const items = [

        !isVisableAllNotTimed ?
            {
                key: '1',
                label: GetWord('Genel'),
                children: <Public setIsRefresh={setIsRefresh} setModal={setModal} selectedData={selectedData} />,
            } : null,
        // {
        //     key: '2',
        //     label: GetWord('Kampanya'),
        //     children: <Campaign />,
        // },
        // {
        //     key: '3',
        //     label: GetWord('Özelleştirilmiş'),
        //     children: <Personalized />,
        // },
        // {
        //     key: '4',
        //     label: GetWord('Kullanıcı Çekme'),
        //     children: <Attractuser setIsRefresh={setIsRefresh} setModal={setModal} />,
        // },
        !isVisableAllNotTimed ?
            {
                key: '5',
                label: GetWord('Market'),
                children: <NotificationMarket setIsRefresh={setIsRefresh} setModal={setModal} />,
            } : null,
        !isVisabledTimed ? {
            key: '6',
            label: GetWord('Timed'),
            children: <NotificatioTimed selectedData={selectedData} setIsRefresh={setIsRefresh} setModal={setModal} />,
        } : null,
        !isVisableAllNotTimed ? {
            key: '7',
            label: GetWord('DependingOnVehicle'),
            children: <NotificationDependingOnVehicle setIsRefresh={setIsRefresh} setModal={setModal} />,
        } : null,
        !isVisableAllNotTimed ? {
            key: '8',
            label: GetWord('Test & Send Then'),
            children: <NotificationTest setIsRefresh={setIsRefresh} setModal={setModal} />,
        } : null,
    ];
    return (
        <div>
            <Tabs defaultActiveKey={defaultActiveKey} items={items} onChange={onChange} />
        </div>
    )
}

export default Notificationmodal