import React from "react";
import ReactDOM from "react-dom/client";
import "./translate/i18n.js";
import { Provider } from "react-redux";
import store from "./stores";
import RouterPage from "./router";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './index.scss';
import { ConfigProvider } from "antd";
import locale from 'antd/locale/tr_TR';
import dayjs from "dayjs";

import 'dayjs/locale/en';

dayjs.locale('en');

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ConfigProvider locale={locale}>
      <Provider store={store}>
        <BrowserRouter>
          <RouterPage />
          <ToastContainer />
        </BrowserRouter>
      </Provider>
    </ConfigProvider>
  </React.StrictMode>
);
