import baseService from './baseService';

export default class NotificationService extends baseService {
    constructor() {
        super();
        this.schemaName = "notifications";
    }


    getNotifications(query) {
        return this.getObject(null, "admin/getNotifications", query);
    }

    delNotification(id) {
        return this.deleteObject(this.schemaName, "delete/" + id)
    }

    postNotificationGeneral(entity) {
        return this.postObject(this.schemaName, "all-user-send", entity)
    }

    postMarket(entity, query) {
        return this.postObject(this.schemaName, "filter-notification-status" + (query ? "?" + query : ""), entity)
    }

    postVehicle(entity, query) {
        return this.postObject(this.schemaName, "filter-notification" + (query ? "?" + query : ""), entity)
    }

    postNotificationTimed(entity) {
        return this.postObject(this.schemaName, "schedule", entity)
    }

    updateNotificationTimed(entity, id) {
        return this.patchObject("admin/schedule", id, entity)
    }

    getScheduledNotifications(query) {
        return this.getObject("admin/schedule", "getNotifications", query);
    }

    delScheduledNotification(id) {
        return this.deleteObject("admin/schedule", id,)
    }

    postNotificationTest(entity) {
        return this.postObject(this.schemaName, "admin-send", entity)
    }

    updatePublicNotification(entity, id) {
        return this.patchObject(this.schemaName, "update/" + id, entity)

    }

}