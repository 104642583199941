import React, { useCallback, useEffect, useState } from 'react'
import './notification.scss'
import { GetWord } from "../../../../utils";
import { Space, Table, Tag, Pagination, Modal, Tabs, Button } from 'antd';
import NewNotification from './modal/notificationmodal'
import Deletemodal from './modal/deletemodal';
import Notificationdetail from './modal/components/notificationdetail';
import Service from '../../../../Service';
import moment from 'moment';


const Notification = () => {
    const [isShowNewNotificationModalOpen, setShowNewNotificationModalOpen] = useState(false);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [isShowNotificationDetailModal, setShowNotificationDetailModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedTab, setSelectedTab] = useState("1");
    const pageSize = 10;
    const [notifications, setNotifications] = useState();
    const [refresh, setIsRefresh] = useState(false);
    const [selectedID, setSelectedID] = useState();
    const [selectedData, setSelectedData] = useState();

    const currentPageData = notifications?.notifications || [];

    const getNotifications = useCallback(() => {
        const service = new Service()
        service.NotificationService.getNotifications(`limit=${pageSize}&offset=${currentPage}`).then((res) => {
            setNotifications(res)
        })
    }, [currentPage])

    useEffect(() => {
        getNotifications();
    }, [getNotifications, currentPage, refresh])

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };


    const showNewNotificationModal = (value) => {
        setShowNewNotificationModalOpen(true);
        setDeleteModalOpen(false);
        setShowNotificationDetailModal(false);
        setSelectedData(value);
    };

    const showDeleteModal = (id) => {
        setShowNewNotificationModalOpen(false);
        setDeleteModalOpen(true);
        setShowNotificationDetailModal(false);
        setSelectedID(id);
    }

    const showNotificationDetailModal = () => {
        setShowNotificationDetailModal(true)
        setShowNewNotificationModalOpen(false);
        setDeleteModalOpen(false)
    }

    const handleOk = () => {
        setShowNewNotificationModalOpen(false);
        setDeleteModalOpen(false)
        setShowNotificationDetailModal(false)
    };

    const handleCancel = () => {
        setShowNewNotificationModalOpen(false);
        setDeleteModalOpen(false)
        setShowNotificationDetailModal(false)
    };

    const columns = [
        {
            title: GetWord('BİLDİRİM'),
            dataIndex: 'title',
            width: '50%',
            key: 'notification',
            render: (text, data) => <div className='notification-table-notification'>
                <button className='notification-table-notification-eye' onClick={setShowNotificationDetailModal}>
                    <i className='icon-Eye notification-table-notification-eye-icon' />
                </button >
                <div className='notification-table-notification-textarea'>
                    <span className='notification-table-notification-textarea-title'>{text}</span>
                    <span className='notification-table-notification-textarea-body'>{data.body}</span>
                </div>
            </div >,
        },
        {
            title: GetWord('TÜR'),
            dataIndex: 'type',
            key: 'type',
            render: (title, data) => {
                const typeLabel = title === 'general' ? 'Genel' : title === 'station' ? 'İstasyon' : title;

                return (
                    <div className='notification-table-type'>
                        <span className='notification-table-type-title'>{typeLabel}</span>
                        <span className='notification-table-type-text'>{data.typeSmall}</span>
                    </div>
                );
            },
        },
        {
            title: <div className='notification-table-date-title'>{GetWord('TARİH & SAAT')}</div>,
            dataIndex: 'date',
            key: 'date',
            render: (date, data) => <div className='notification-table-date'>
                <span className='notification-table-date-date'>{moment(date).format("LL")}</span>
                <span className='notification-table-date-time'>{moment(date).format("LT")}</span>
            </div >,
            sorter: (a, b) => a.date - b.date,
        },
        {
            title: GetWord('DURUM'),
            key: 'status',
            dataIndex: 'status',
            render: (_, { status }) => (
                <>
                    {/* {status.map((status) => {
                        let color = status.length > 5 ? 'geekblue' : 'green';
                        if (status === GetWord('Gönderildi')) {
                            color = 'cyan';
                        }
                        if (status === GetWord('Yayınlanacak')) {
                            color = 'yellow';
                        }
                        if (status === GetWord('Silindi')) {
                            color = 'red';
                        } */}
                    {/* return ( */}
                    <Tag color={'cyan'} key={GetWord}>
                        {GetWord('Gönderildi')}
                    </Tag>
                    {/* );
                    })} */}
                </>
            ),
        },
        {
            title: GetWord('SEÇENEKLER'),
            key: 'options',
            render: (_, record) => (
                <Space size="middle">

                    <button className='notification-table-options-edit' onClick={() => showNewNotificationModal(record)}>
                        <i className='icon-Edit notification-table-options-edit-icon' />
                    </button>
                    <button className='notification-table-options-delete' onClick={() => showDeleteModal(record.notificationId)} >
                        <i className='icon-Delete notification-table-options-delete-icon' />
                    </button>
                </Space>
            ),
        },
    ];

    return (
        <div className='notification'>
            <div class="notification-header">
                <p className='notification-header-title'>
                    {GetWord('Bildirim')}
                </p>

                <div className='notification-header-button'>
                    <button className='notification-header-button-style' onClick={() => showNewNotificationModal()}>
                        <i className='icon-Notification-Add notification-header-button-icon' />
                        <span className='notification-header-button-text'> {GetWord('Yeni Bildirim')}</span>
                    </button>
                </div>
            </div>
            <div className='notification-modal-public'>
                <Modal title={GetWord('Bildirim Oluştur')}
                    destroyOnClose={true}
                    open={isShowNewNotificationModalOpen} onOk={handleOk} onCancel={handleCancel} className={'notification-modal ' + (selectedTab === "2" ? "notification-modal-selected2" : selectedTab === '3' ? "notification-modal-selected3" : "")}
                    // footer={[
                    //     <div className='notification-modal-public-sendbutton'>
                    //         <Button key="submit" type="primary" onClick={handleOk} className='notification-modal-public-sendbutton-style'>
                    //             <span className='notification-modal-public-sendbutton-text'>
                    //                 Gönder</span>
                    //         </Button>
                    //     </div>,
                    // ]}
                    // style={{ width: selectedTab === "2" ? 1800 : 826 }}
                    // width={selectedTab === "2" ? 1800 : 826}
                    footer={false}
                >
                    <NewNotification isVisabledTimed={true} setSelectedTab={setSelectedTab} setIsRefresh={setIsRefresh} setModal={setShowNewNotificationModalOpen} selectedData={selectedData} />
                </Modal>
            </div>

            <div className='notification-table'>
                <Table columns={columns} dataSource={currentPageData} pagination={false}
                />
            </div>

            <div className='notification-deletemodal'>
                <Modal title={GetWord('Bu bildirimi siliyorsun. Emin misin?')} footer={null} closable={false} open={isDeleteModalOpen} onCancel={handleCancel} className='notification-deletemodal'
                >
                    <Deletemodal setIsRefresh={setIsRefresh} handleCancel={handleCancel} id={selectedID} />
                </Modal>

            </div>

            <div className='notification-notificationdetailmodal'>
                <Modal title={GetWord('Bildirim Detayı')} width={825} footer={null} open={isShowNotificationDetailModal} onCancel={handleCancel} className='notification-notificationdetailmodal'
                >
                    <Notificationdetail />
                </Modal>

            </div>



            <div className='pagination-container'>
                <span className='pagination-label'>{GetWord('Sayfa')} </span>
                <Pagination
                    className="notification-pagination"
                    current={currentPage}
                    pageSize={pageSize}
                    total={notifications?.totalCount}
                    onChange={handlePageChange}  // Sayfa değiştiğinde handlePageChange çağrılır
                    showSizeChanger={false}
                />
            </div>
        </div>
    )
}

export default Notification;
