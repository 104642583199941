import baseService from "../baseService";

export default class AdminService extends baseService {
    constructor() {
        super();
        this.schemaName = null;
    }

    login(entity) {
        return this.postObject("admin", "login", entity)
    }

    refreshToken(tkn) {
        return this.getObject("admin", "refresh-token", tkn)
    }


    getUserList(entity) {
        return this.getObject(this.schemaName, "users", entity)
    }

    getUserDetail(data) {
        return this.getObject(this.schemaName, "users/" + data)
    }

    getStationList(data) {
        return this.getObject(this.schemaName, "stations/" + data)
    }

    getStationDetail(data) {
        return this.getObject(this.schemaName, "stations/detail/" + data)
    }

    updateUser(id, entity) {
        return this.patchObject(this.schemaName, "users/" + id, entity)
    }

    getStationComments(id) {
        return this.getObject(this.schemaName, "stations/comments/" + id)
    }

    getStoryList(entity) {
        return this.getObject(this.schemaName, "stories", entity)
    }

    getExpenseList(entity) {
        return this.getObject(this.schemaName, "expense/list-expenses/", entity)
    }





}