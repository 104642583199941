import axios from "axios";
import { getLocalstorage, setLocalstorage } from "../utils";
import { Decrypt, Encrypt } from "../utils/encrypted";
import { RefleshToken } from "../utils/firebase";
import moment from "moment";

// const globalApiURL = "https://api.mobsmile.com/petros/";
const globalApiURL = "https://api.mobsmile.com/petros/dev/v2/";

export default class baseService {
  postObject(schemaName, funcName, entity) {
    var url = globalApiURL + schemaName + "/" + funcName;
    return this.callServiceMethod(url, entity, "POST");
  }


  getObject(schemaName, funcName, data) {
    var url =
      globalApiURL + (schemaName ? schemaName + "/" : "") + funcName + (data ? "?" + data : "");
    return this.callServiceMethod(url, data, "GET");
  }

  putObject(schemaName, funcName, entity) {
    var url = globalApiURL + schemaName + "/" + funcName;
    return this.callServiceMethod(url, entity, "PUT");
  }

  deleteObject(schemaName, funcName, data) {
    var url =
      globalApiURL + schemaName + "/" + funcName + (data ? "?" + data : "");
    return this.callServiceMethod(url, null, "DELETE");
  }

  patchObject(schemaName, funcName, entity) {
    var url = globalApiURL + schemaName + "/" + funcName;
    return this.callServiceMethod(url, entity, "PATCH");
  }
  patchUrl(schemaName, funcName, data) {
    var url =
      globalApiURL + schemaName + "/" + funcName + "?" + (data ? data : "");
    return this.callServiceMethod(url, null, "PATCH");
  }

  callServiceMethod(url, data, method, cancelToken) {
    const localtionUser = getLocalstorage("user") ? Decrypt(getLocalstorage("user")) : "";
    var user = localtionUser ? JSON.parse(localtionUser) : null;

    if (user) {
      const expirationDate = moment(user.expirationDate).add(-5, "minute");
      const currentDate = moment();

      if (expirationDate < currentDate) {
        console.log("refresh token alınıyor...");
        const token = user?.idToken;
        const headers = {
          Authorization: token ? `Bearer ${token}` : "",
        };

        this.callAnyServiceMethod(globalApiURL + "admin/refresh-token", { token: user.refreshToken }, "POST", headers).then(async (res) => {
          user = res.data;
          setLocalstorage("tkn", Encrypt(JSON.stringify(res.data.idToken)));
          setLocalstorage("user", Encrypt(JSON.stringify(res.data)));
        });

      }


    }

    const token = user?.idToken;

    var headers = {
      Authorization: token ? "Bearer " + token : "",
    };

    return this.callAnyServiceMethod(url, data, method, headers, cancelToken);
  }

  async callAnyServiceMethod(url, data, method, headers, cancelToken) {
    var authOptions = {
      method,
      url,
      data,
      headers,
      withCredentials: false,
      cancelToken,
    };

    return await axios(authOptions)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (error.request.status == 401) {
          RefleshToken();
        }
        return error;

      });
  }
}
